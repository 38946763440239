/*
 * Expend search input
 */

(function($) {
  $(document).ready(function() {
    // Options
    var DURATION = 500;

    // Script
    var $form = $('.expend-search');
    var $inputSearch = $form.find('input');
    var isScaleDown = false;

    initialize = function() {
      $inputSearch.css('width', 0);
      isScaleDown = true;

      $form.submit(function( event ){
        if ($inputSearch.val() === '') {
          toggle();
          event.preventDefault();
        }
      });
    };

    toggle = function () {
      if (isScaleDown)
        scaleUp();
      else
        scaleDown();
      isScaleDown = !isScaleDown;
    };

    scaleUp = function() {
      $inputSearch.animate({'width': '100%'}, DURATION);
      $inputSearch.addClass("open").show();
    };

    scaleDown = function() {
      $inputSearch.animate({'width': 0}, DURATION);
      $inputSearch.removeClass("open");
    };

    initialize();

  });
}(window.jQuery.noConflict()));
